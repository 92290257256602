import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchBackground from "src/firebase/fetchBackgroundProfile";
import calculateApplicationScreeningScore from "src/pages/Admin/calculateApplicationScreeningScore";
import triageApplicationData from "src/pages/Admin/triageApplicationData";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { UserAccount } from "src/types/User";
import useErrorHandler from "./useErrorHandler";

const useBackgroundProfile = (user: UserAccount) => {
  const [background, setBackground] = useState<BackgroundFormData>();
  const [loading, setLoading] = useState(true);
  const [applicationScore, setApplicationScore] = useState<
    number | null | undefined
  >();
  const [hasEligibleApplicationScore, setHasEligibleApplicationScore] =
    useState<boolean | undefined>();

  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  const loadBackground = useCallback(() => {
    setLoading(true);
    fetchBackground(user.uid, clients)
      .then(setBackground)
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [clients, user.uid, errorHandler]);

  useEffect(() => {
    loadBackground();
  }, [loadBackground]);

  useEffect(() => {
    if (loading) return;
    if (!background) {
      setApplicationScore(null);
    } else {
      const applicationScore = calculateApplicationScreeningScore(
        triageApplicationData(background)
      );
      setApplicationScore(applicationScore);
      if (applicationScore >= 0) setHasEligibleApplicationScore(true);
      else {
        setHasEligibleApplicationScore(false);
      }
    }
  }, [background, loading]);

  return {
    background,
    loading,
    refetch: loadBackground,
    setBackground,
    applicationScore,
    hasEligibleApplicationScore,
  };
};

export default useBackgroundProfile;
