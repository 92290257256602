/* eslint-disable react/no-unused-prop-types */
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAccount } from "src/types/User";

import CohortCondictionalAcceptanceTable from "src/pages/AdminScreen/CohortCondictionalAcceptanceTable";
import { Cohort } from "src/types/Cohort";

export type SupportTier = "risky" | "attention" | "healthy" | "none";
export type UserWithPriorityDescription = UserAccount & {
  description: string;
  tierOfSupport: SupportTier;
};

type CoachSegmentPriorityCardProps = {
  title: string;
  subtitle?: string;
  users: UserWithPriorityDescription[];
  cohortsById: Record<string, Cohort>;
};

const CoachSegmentPriorityCard: React.FC<CoachSegmentPriorityCardProps> = ({
  title,
  users,
  subtitle,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardActionArea onClick={handleExpandClick}>
        <CardHeader
          title={title}
          subheader={t("Total Students: {{ totalStudents }}", {
            totalStudents: users.length,
          })}
        />
      </CardActionArea>
      <CardContent>
        <Typography>{subtitle}</Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CohortCondictionalAcceptanceTable participants={users} />
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default CoachSegmentPriorityCard;
