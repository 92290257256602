import { differenceInDays } from "date-fns";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import {
  SupportTier,
  UserWithPriorityDescription,
} from "./CoachSegmentPriorityCard";
import determineEngagementRisk from "./determineEngagementRisk";

interface SplitUsersOptions {
  getOverdueMilestones: (
    user: UserAccount
  ) => StudentPlanMilestone[] | undefined;
  getUpcomingMilestones: (
    user: UserAccount
  ) => StudentPlanMilestone[] | undefined;
  getEngagementRatioByUser: (user: UserAccount) => number | undefined;
  getLessonProgress: (user: UserAccount) => number | undefined;
}

export default function splitUsersByPriority(
  users: UserAccount[],
  {
    getOverdueMilestones,
    getUpcomingMilestones,
    getEngagementRatioByUser,
    getLessonProgress,
  }: SplitUsersOptions
) {
  const today = new Date();

  const jobSearching: UserWithPriorityDescription[] = [];
  const skillsTraining: UserWithPriorityDescription[] = [];
  const advanced: UserWithPriorityDescription[] = [];
  const beginners: UserWithPriorityDescription[] = [];
  const newcomers: UserWithPriorityDescription[] = [];

  users.forEach((user) => {
    const overdueMilestones = getOverdueMilestones(user) || [];
    const lessonProgress = getLessonProgress(user);
    const lastStatusUpdate = user.applicationStatusLastUpdatedAt
      ? new Date(user.applicationStatusLastUpdatedAt!)
      : undefined;
    const engagementRatio = getEngagementRatioByUser(user);
    const upcomingMilestones = getUpcomingMilestones(user) || [];

    let description = "";
    let tierOfSupport: SupportTier = "healthy"; // default tier of support

    // Group "job searching" users
    if (
      user.employmentStatus === "job_searching" ||
      user.enrollmentStatus === "licensed"
    ) {
      description = "Actively looking for jobs or licensed.";
      jobSearching.push({ ...user, description, tierOfSupport });
      return;
    }

    // Group "skills training" users
    if (
      user.enrollmentStatus === "skills_training" ||
      user.enrollmentStatus === "completed_skills_training" ||
      user.applicationStatus === "enrolled"
    ) {
      description = "In skills training or ready to be enrolled.";
      skillsTraining.push({ ...user, description, tierOfSupport });
      return;
    }

    // Group "newcomers" based on the last application status update
    if (lastStatusUpdate && differenceInDays(today, lastStatusUpdate) <= 7) {
      if (engagementRatio && engagementRatio <= 1) {
        description = `Low engagement ratio`;
        tierOfSupport = "attention";
      } else {
        description = "Healthy.";
        tierOfSupport = "healthy";
      }
      newcomers.push({ ...user, description, tierOfSupport });
      return;
    }

    // Group "advanced" (formerly help students) based on video progress > 10
    if (lessonProgress && lessonProgress > 10) {
      const { description, tierOfSupport } = determineEngagementRisk({
        lastLoggedIn: user.lastLoggedIn,
        overdueMilestones,
        upcomingMilestones,
        engagementRatio,
      });
      advanced.push({ ...user, description, tierOfSupport });
    }
    // less than 10 lessons completed
    else {
      const { description, tierOfSupport } = determineEngagementRisk({
        lastLoggedIn: user.lastLoggedIn,
        overdueMilestones,
        upcomingMilestones,
        engagementRatio,
      });
      beginners.push({ ...user, description, tierOfSupport });
    }
  });

  return {
    jobSearching,
    skillsTraining,
    advanced,
    beginners,
    newcomers,
  };
}
