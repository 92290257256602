import { Chip, ChipProps, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { IncarcerationStatus } from "src/types/UserStatus";

type Props = {
  status?: keyof typeof IncarcerationStatus;
} & ChipProps;

function statusToColor(status: keyof typeof IncarcerationStatus) {
  switch (status) {
    case "in_custody_pre_trial":
      return grey;
    case "in_custody_sentenced":
      return orange;
    case "out_custody_supervision":
      return blue;
    case "out_custody_unrestricted":
      return green;
    case "in_custody_transferred":
      return red;
    default:
      return grey;
  }
}

export default function IncarcerationStatusChip({ status, ...rest }: Props) {
  if (!status) return <Typography>-</Typography>;
  return (
    <Chip
      sx={{
        color: statusToColor(status)[900],
        borderColor: statusToColor(status)[100],
        backgroundColor: statusToColor(status)[100],
      }}
      label={IncarcerationStatus[status]}
      {...rest}
    />
  );
}
